<template>
    <div class="moreLvWeb">
        <div v-if="tabBar === 0" class="moreLvWeb-space">
            <div class="allow-click" @click="back()">出库记录</div>
            <i class="el-icon-arrow-right"></i>
            <div class="allow-click currentWeb">附件</div>
        </div>

        <div v-else-if="tabBar === 1" class="moreLvWeb-space">
            <div class="allow-click" @click="back()">入库记录</div>
            <i class="el-icon-arrow-right"></i>
            <div class="allow-click currentWeb">附件</div>
        </div>

        <div v-else-if="tabBar === 2" class="moreLvWeb-space">
            <div class="allow-click" @click="back()">供应商</div>
            <i class="el-icon-arrow-right"></i>
            <div class="allow-click currentWeb">物品</div>
        </div>

        <div v-else-if="tabBar === 3" class="moreLvWeb-space">
            <div class="allow-click" @click="back()">盘点任务</div>
            <i class="el-icon-arrow-right"></i>
            <div class="allow-click currentWeb">负责盘点物品</div>
        </div>

        <div v-else-if="tabBar === 4" class="moreLvWeb-space">
            <div class="allow-click" @click="back()">供应商管理</div>
            <i class="el-icon-arrow-right"></i>
            <div class="allow-click currentWeb">附件</div>
        </div>
    
    </div>
</template>

<script>
export default {
    props:{
        tabBar:Number,
    },
    methods:{
        back(){
            this.$emit("goBack",this.tabBar);
        }
    }
}
</script>

<style>
    .moreLvWeb{
        width:100%;
        height:60px;
        background-color:#FFFFFF;
        font-size:24px;
    }
    .moreLvWeb-space{
        padding:0px 30px;
    }
    .moreLvWeb .allow-click{
        line-height:60px;
        color:#999999;
        text-decoration-line:none;
    }
    .moreLvWeb .moreLvWeb-space i{
        margin:0px 10px;
        display:inline-block;
        vertical-align:middle;
        color:#999999;
    }
    .moreLvWeb .currentWeb{
        color:#000;
    }
</style>